.loader-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 95%;
}

.lds-spinner {
  color: var(--secondary);
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--secondary);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}





//======================================================================

.spinner-2 {
  width: 20em;
  height: 20em;
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-2 .face {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  animation: animate 3s linear infinite;
}

.spinner-2 .face:nth-child(1) {
  width: 100%;
  height: 100%;
  color: var(--orange);
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.2em 0.2em 0em 0em;
  --deg: -45deg;
  animation-direction: normal;
}

.spinner-2 .face:nth-child(2) {
  width: 70%;
  height: 70%;
  color: var(--action-accent);
  border-color: currentColor currentColor transparent transparent;
  border-width: 0.2em 0em 0em 0.2em;
  --deg: -135deg;
  animation-direction: reverse;
}

.spinner-2 .face .circle {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: rotate(var(--deg));
  transform-origin: left;
}

.spinner-2 .face .circle::before {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  content: '';
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 50%;
  box-shadow: 0 0 2em,
  0 0 4em,
  0 0 6em,
  0 0 8em,
  0 0 10em,
  0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes animate {
  to {
    transform: rotate(1turn);
  }
}

//======================================================================
$duration: 1.2s;
$size: 5rem;



.spinner-3 {
  position: fixed;
  top: 50%;
  left: 50%;
  height: $size;
  width: $size;
  transform: translateX(-50%) translateY(-50%);
}

.container-spinner-3 {
  position: absolute;
  top: 0;
  left: 0;
  height: $size;
  width: $size;

  &:nth-of-type(1) {
    transform: rotate(0);
  }

  &:nth-of-type(2) {
    transform: rotate(180deg);
  }
}

.block {
  position: absolute;
  top: 0;
  left: 0;
  height: $size;
  width: $size;
  background: var(--secondary);
  animation: move3 $duration * 4 steps(1) infinite;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }

  &:nth-of-type(2) {
    animation-delay: $duration / 5;
  }

  &:nth-of-type(3) {
    animation-delay: 2 * $duration / 5;
  }

  &:nth-of-type(4) {
    animation-delay: 3 * $duration / 5;
  }

  > .item {
    float: left;
    height: $size / 2;
    width: $size / 2;
    background: var(--secondary);

    &:nth-of-type(1),
    &:nth-of-type(2) {
      animation: move1 $duration linear infinite;
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      animation: move2 $duration linear infinite;
    }

    &:nth-of-type(1) {
      animation-delay: 0s;
    }

    &:nth-of-type(2) {
      animation-delay: $duration / 10;
    }

    &:nth-of-type(3) {
      animation-delay: 2 * $duration / 10;
    }

    &:nth-of-type(4) {
      animation-delay: 3 * $duration / 10;
    }
  }
}

@keyframes move1 {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  7% {
    transform: rotate(180deg);
    transform-origin: 100% 0%;
  }
  15% {
    transform: rotate(270deg);
    transform-origin: 150% -50%;
  }
  20% {
    transform: rotate(270deg);
    transform-origin: 150% -50%;
  }
  70% {
    transform: rotate(270deg);
    transform-origin: 150% -50%;
    opacity: 0;
  }
  100% {
    transform: rotate(270deg);
    transform-origin: 150% -50%;
    opacity: 0;
  }
}

@keyframes move2 {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  7% {
    transform: rotate(-180deg);
    transform-origin: 100% 100%;
  }
  15% {
    transform: rotate(-270deg);
    transform-origin: 150% 150%;
  }
  20% {
    transform: rotate(-270deg);
    transform-origin: 150% 150%;
  }
  70% {
    transform: rotate(-270deg);
    transform-origin: 150% 150%;
    opacity: 0;
  }
  100% {
    transform: rotate(-270deg);
    transform-origin: 150% 150%;
    opacity: 0;
  }
}

@keyframes move3 {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
