.range {
  background: var(--secondary);
  height: 2rem;
  width: 100%;
  border-radius: 3rem;
  box-shadow: 1px 5px 5px rgba(var(--main-background), 0.3);

  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 90%;
  height: 100%;
  background: transparent;

  &:focus {
    outline: none;
  }

  //WEBKIT
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--main);
    margin-top: -5px;
    box-shadow: 1px 1px 2px rgba(#000, 0.5);

    cursor: pointer;

  }

  &::-webkit-slider-runnable-track {
    width: 60%;
    height: 9px;
    background: var(--background);
    border-radius: 3rem;

    transition: all 0.5s;
    cursor: pointer;
  }

  &:hover::-webkit-slider-runnable-track {
    background: var(--action-accent-lighten);
  }

  // IE

  &::-ms-track {
    width: 60%;
    cursor: pointer;
    height: 9px;

    transition: all 0.5s;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--main);
    margin-top: -5px;
    box-shadow: 1px 1px 2px rgba(#000, 0.5);

    cursor: pointer;
  }

  &::-ms-fill-lower {
    background: #bdbdbd;
    border-radius: 3rem;
  }
  &:focus::-ms-fill-lower {
    background: var(--action-accent-lighten);
  }
  &::-ms-fill-upper {
    background: var(--background);
    border-radius: 3rem;
  }
  &:focus::-ms-fill-upper {
    background: var(--action-accent-lighten);
  }

  //FIREFOX
  &::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--background);
    margin-top: -5px;
    box-shadow: 1px 1px 2px rgba(#000, 0.5);

    cursor: pointer;
  }

  &::-moz-range-track {
    width: 60%;
    height: 9px;
    background: var(--background);
    border-radius: 3rem;

    transition: all 0.5s;
    cursor: pointer;
  }
  &:hover::-moz-range-track {
    background: var(--action-accent-lighten);
  }
}
