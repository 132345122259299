@import 'CSS/var';
@import 'CSS/fonts';

@import 'CSS/checkboks', 'CSS/button', 'CSS/select', 'CSS/calendar', 'CSS/input', 'CSS/range';
@import 'CSS/badges', 'CSS/spiner', 'CSS/filter', 'CSS/modal', 'CSS/orders';
@import 'CSS/sidebar', 'CSS/tabs', 'CSS/login', 'CSS/cashbox';



%shadow-3 {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.32);
}

// Анимации
@keyframes ani-items {
  0% {max-height: 0;}
  100% {max-height: 450px;}
}

$themes: (
        "light": (
                "main-background": #ECE0CF,
                "background": #F0F0F0,
                'second-bacground': #272B3A,
                "accent": #1E1E1E,
                "accent-light": #E8E8E8,
                "main": #444654,
                "secondary": #696B76,
                "disabled": #FCFCFC,
                "action-accent": #4690FF,
                "success": #52B852,
                "warning": #EFCA2F,
                "error": #D91B26,
                "orange": #FF974B,
                "purple": #744096,
                "delivery": #388d95
        ),
        "dark": (
                "main-background": #191919,
                "background": #242424,
                'second-bacground': #272B3A,
                "accent": #FFFFFF,
                "accent-light": #E8E8E8,
                "main": #DADADA,
                "secondary": #767676,
                "disabled": #292929,
                "action-accent": #466FFF,
                "success": #45814E,
                "warning": #CBA712,
                "error": #C73B43,
                "orange": #FF974B,
                "purple": #652D8A,
                "delivery": #388d95
        )
);

@each $theme-name, $properties in $themes {
  #root.#{$theme-name} {
    // define a css variable for each property
    @each $name, $property in $properties {
      --#{$name}: #{$property};
      @if $name == "secondary" {
        --#{$name}-darken: #{darken($property, 13%)};
        --#{$name}-no-active: #{rgba($property, 0.4)};
        --#{$name}-lighten: #{lighten($property, 13%)};
      } @else if $name == "main" {
        --#{$name}-darken: #{darken($property, 10%)};
        --#{$name}-no-active: #{rgba($property, 0.4)};
        --#{$name}-lighten: #{lighten($property, 6.25%)};
      } @else if $name == "action-accent" {
        --#{$name}-no-active: #{rgba($property, 0.4)};
        --#{$name}-lighten: #{lighten($property, 6.25%)};
      } @else {
          --#{$name}-no-active: #{rgba($property, 0.4)};
          --#{$name}-lighten: #{lighten($property, 6.25%)};
      }
    }
  }
}


html, body, #root  {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
}

#root {
  width: inherit;
  height: inherit;
  background: var(--main-background);
  color: var(--main);
}

.main-container {
  @include font-regular;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.main-box {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  height: inherit;
  box-sizing: border-box;
}

.main-content {
  display: flex;
  flex-direction: column;
  padding: $boxPadding $boxPadding $boxPadding $boxPadding/2;
  max-height: 100%;
  width: 100%;
  gap: $boxPadding;
  overflow: auto;
  color: var(--main);
  box-sizing: border-box;

  &_setting {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.setting-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: $boxPadding;
  box-sizing: border-box;
}


input, textarea {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: var(--background);
  color: var(--main);
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--secondary);
  -webkit-box-shadow: 0 0 0 40rem var(--background) inset;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

table {
  overflow: auto;
  min-width: 100%;
  border-collapse: collapse;
}

th {
  border-bottom: 1px solid var(--main);
  resize: horizontal;
}

tr {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-height: 50px;
  border-bottom: 1px solid var(--secondary);
}

td {
  max-width: 0;
  padding: 0;
  text-overflow: ellipsis;
}

p {
  padding: 0;
  margin: 0;
}



.label {
  padding-bottom: 5px;
  color: var(--main);
  @include font-regular($weight-medium);
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 6px;
  border: 1px solid var(--main);
  border-radius: $borderRadius;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--background);
  fill: var(--action-accent);
  //color: var(--main);
}

.textarea {
  padding: 5px;
  background-color: var(--background);
  //color: var(--main);
  border: 1px solid var(--main);
  border-radius: $borderRadius;
  width: 100%;
  min-height: 55px;
  box-sizing: border-box;
  transition: color 1s;
}

h1, h2, h3, h4, h5 {
  margin: 0
}

h1 {
  font-weight: bold;
  font-size: 56px;
  line-height: 68px;
  letter-spacing: -1.25px;
}

h2 {
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.89px;
}

h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.47px;
}

h4 {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.26px;
}

h5 {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.26px;
  text-transform: uppercase;
  color: var(--action-accent);
}

.icon {
  display: block;
  width: 16px;
  height: 16px;
  transition: transform 0.5s;

  &_10 {
    width: 10px;
    height: 10px;
  }
  &_12 {
    width: 12px;
    height: 12px;
  }

  &_20 {
    width: 20px;
    height: 20px;
  }

  &_24 {
    width: 24px;
    height: 24px;
  }

  &_32 {
    width: 32px;
    height: 32px;
  }

  &_rotate-90 {
    transform: rotate(90deg);
  }

  &_rotate-180 {
    transform: rotate(180deg);
  }

  &_rotate-270 {
    transform: rotate(-90deg);
  }

}




.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 12px 20px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: var(--background);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.42);
  border-radius: $borderRadius;
}

.content-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.42);
  border-radius: $borderRadius;
}

.custom-filters-container {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}

.input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;

  border: 1px solid var(--secondary);
  border-radius: $borderRadius;
}



.th {
  @include font-regular($weight-medium);
  height: 30px;
  border-bottom: 1px solid var(--main);
  color: var(--main);
  text-align: left;

  $th-widths: (20, 32, 15, 40, 50, 60, 70, 80, 90, 100, 150, 160, 180);
  @each $width in $th-widths {
    &_w#{$width} {
      width: #{$width}px;
    }
  }
}

.td {
  height: 35px;
  text-align: left;

  &_title {
    vertical-align: bottom;
    @include font-regular($weight-medium);
    color: var(--main-darken);
  }
  &_number {
    //padding-left: 10px;
  }
  &_total {
    padding: 0 10px;
    text-align: right;
  }
  &_green {
    //@include font-regular($weight-medium);
    color: var(--success);
  }
  &_red {
    //@include font-regular($weight-medium);
    color: var(--error);
    fill: var(--error);
  }

  &_input {
    height: 25px;
    background-color: var(--second-bacground);
  }

  &_checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tr {
  fill: var(--main);

  &:hover {
    border-bottom: 1px solid var(--main);
  }

  &_tools {
    fill-opacity: 0;

    &:hover {
      fill-opacity: 1;
    }
  }

  &_selected {
    background-color: var(--second-bacground);
  }

  &_deleted {
    color: var(--secondary);
    border-left: 4px solid var(--error);
    text-decoration: line-through;
  }

  &_set {
    display: flex;
    flex-direction: row;
  }
  &_set-button-edit {
    cursor:pointer;
    fill: var(--action-accent);
    padding: 2px 4px;
  }
  &_set-button-delete {
    cursor: pointer;
    fill: var(--error);
    padding: 2px 4px;
  }
  &_no-underline {
    //color: var(--secondary);
    border-bottom: none;
  }
  &_work {
    fill-opacity: 1;
  }

}





.row {
  display: flex;
  flex-direction: row;
}

.colm {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}



.status-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.drop-list-statuses {
  position: absolute;
  padding: 10px 5px;
  top: 25px;
  left: 0;
  right: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--main);
  border-radius: $borderRadius;
  background-color: var(--background);
  box-sizing: border-box;
  z-index: 2;
}

.drop-list-statuses-top {
  bottom: 25px;
  top: auto;
}

.field-options {
  position: relative;

  &__drop-list {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 1px solid var(--action-accent);
    border-radius: $borderRadius;
    background-color: var(--background);
    z-index: 5;
  }

  &__title {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 10px;
    height: 30px;
    min-width: min-content;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--action-accent);
    border-radius: $borderRadius;
    color: var(--main);
    fill: var(--action-accent);
  }

  &__drop-items {
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 6px;
    animation: ani-items 0.5s forwards;
    opacity: 1;
    overflow: auto;

  }

  &__buttons {
    display: flex;
    padding: 0 6px 6px 6px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
  }
}



.table-orders-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  width: inherit;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.table-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  width: inherit;
  overflow: auto;
  box-sizing: border-box;
}

.table-header {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;


  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 8px;
    fill: var(--main);
    cursor: grab;

    &:hover {
      color: var(--main-darken);
    }
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }

  &__resize {
    display: flex;
    cursor: col-resize;
    align-items: center;
    height: 40px;
    z-index: 1;
    fill: transparent;

    &:active {
      width: 0;
      position: absolute;
      top: 3px;
      height: 100%;
      border-right: 1px solid var(--main);
      cursor: col-resize;
    }
  }

  &__dragOver {
    display: none;
    width: 4px;
    height: 40px;
    background-color: var(--action-accent);
    position: relative;
  }

  &__overArea {
    width: 1px;

    &:before {
      background-color: red;
      opacity: 0.0;
      content: '';
      width: 40px;
      height: 40px;
      display: block;
      position: relative;
      right: 20px;
    }
  }

  &:hover {
    .table-header__resize  {
      fill: var(--secondary);
    }
  }
}


@keyframes client-cell {
  0% {
    opacity: 0;
    left: 3000px;
    align-items: flex-start;
    padding: 0;
  }
  100% {
    opacity: 1;
    left: 0;
    align-items: center;
    padding: 3px;
  }
}

.receipt {
  position: relative;
  margin: 0 1%;
  top: -6px;
  padding: 6px;
  width: 94%;
  border: 1px solid var(--secondary);
  border-top: none;
  background-color: var(--second-bacground);

  &:after {
    content: "";
    position: absolute;
    background-repeat: repeat;
    height: 5px;
    background-size: 10px 10px;
    background-color: var(--second-bacground);
    background-image: radial-gradient(circle at 5px 5px, var(--background) 5px, transparent 5px);
    right: 0;
    left: 0;
    bottom: -5px;
  }

  &__hol {
    width: 100%;
    height: 10px;
    border-radius: 6px;
    border: 1px solid var(--secondary);
    background-color: var(--main-background);
    box-sizing: border-box;
  }

}

.warning {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 10px;
  fill: var(--orange);
  border: 1px solid var(--orange);
  border-radius: $borderRadius;
  box-sizing: border-box;
}



@keyframes client-cell-first {
  0% {padding: 0;}
  100% {padding: 3px;}
}

@keyframes client-cell-container {
  0% {margin-top: 0;}
  100% {margin-top: 5px;}
}

%tr-cell-client {
  transition: all 100ms;
  transition-delay: 0s;
  //transition-timing-function: ease;
}
%tr-cell-client-back {
  transition: all 200ms;
  transition-delay: 1s;
  //transition-timing-function: ease;
}

.cell {
  height: 40px;
  display: flex;
  gap: 1px;
  padding: 0 15px 0 5px;
  align-items: center;

  &_image {
    height: 40px;
  }

  &_label {
    color: var(--action-accent);
    fill: var(--error);
    cursor: pointer;
    gap: 4px;
    &:hover {
      text-decoration: underline;
    }
  }

  &_date {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    width: 100%;
  }

  &_date-payment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-right: 10px;
    grid-gap: 3px;
    gap: 3px;
    fill: var(--error);
    color: var(--secondary);
  }

  &_cashflow-category {
    display: flex;
    flex-direction: row;
    gap: 3px;
    fill: var(--secondary);
    color: var(--secondary);
  }

  &_text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &_text-icon {
    display: flex;
    gap: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    fill: var(--error);
  }

  &_client {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border: 0 solid var(--secondary);
    border-radius: $borderRadius;
    box-sizing: border-box;
    padding: 0 10px 0 0;
    position: absolute;
    width: 100%;
    height: max-content;
    top: 0;
    left: 0;
    cursor: default;
    z-index: 2;
    @extend %tr-cell-client;

    &_title {
      width: 100%;
      @extend .nowrap;
    }

    .bt {
      border-width: 0;
      height: 0;
      @extend %tr-cell-client;
      svg {
        height: 0;
        width: 0;
        @extend %tr-cell-client;
      }
    }

    .client-phones-container {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      gap: 0;
      @extend %tr-cell-client;

      div:first-child {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 4px;
        @extend %tr-cell-client;
      }
      @for $i from 2 through 10 {
        div:nth-child(#{$i}) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          gap: 4px;
          height: 0;
          width: 0;
          @extend %tr-cell-client;
        }
      }
    }

    &:hover {
      top: -10px;
      padding: 10px;
      background-color: var(--disabled);
      border-width: 1px;
      z-index: 3;
      width: min-content;
      @extend %tr-cell-client-back;

      .bt {
        border-width: 1px;
        height: 20px;
        @extend %tr-cell-client-back;
        svg {
          height: 12px;
          width: 12px;
          @extend %tr-cell-client-back;
        }
      }

      .client-phones-container {
        margin-top: 10px;
        gap: 5px;
        @extend %tr-cell-client-back;
        @for $i from 1 through 10 {
          div:nth-child(#{$i}) {
            padding: 3px 0;
            height: 100%;
            width: 100%;
            @extend %tr-cell-client-back;
          }
        }
      }
    }
  }
}



.status {
  padding: 4px 10px;
  border-radius: $borderRadius;
  max-width: max-content;
  cursor: pointer;
  @include font-regular($weight-medium);
  color: var(--background);
}


.client-phone {
  color: var(--secondary);
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  &__arrow {
    padding: 4px;
    cursor: pointer;
    fill: var(--action-accent);
    &:hover {
      fill: var(--action-accent-lighten);
    }
  }
  &__active {
    @include font-regular($weight-medium);
    cursor: pointer;
    padding: 4px;
    color: var(--action-accent);
  }
  &__page {
    cursor: pointer;
    padding: 4px;
    color: var(--main);
    &:hover {
      color: var(--main-lighten);
    }
  }
  &__title {
    color: var(--secondary);
  }
}

.cell-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 312px;

  &__cell {
    display: flex;
    margin: 2px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--background);
    background-color: var(--secondary);
  }
}

.empty_table {
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
}
.table {
  min-height: 200px;
}

.warehouse-select {
  position: relative;

  &__tip {
    position: absolute;
    width: 220px;
    top: -48px;
    border: 1px solid var(--action-accent);
    border-radius: $borderRadius;
    background-color: var(--second-bacground);
    box-sizing: border-box;
    padding: 6px;

    &:after {
      content: ' ';
      position: absolute;
      bottom: -6px;
      left: 30px;
      width: 10px;
      height: 10px;
      border-right: 1px solid var(--action-accent);
      border-bottom: 1px solid var(--action-accent);
      transform: rotate(45deg);
      background-color: var(--second-bacground);
    }
  }
}

.phone {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__icon-trash {
    position: absolute;
    padding: 4px 10px;
    right: -35px;
    top: 4px;

  }
  &__box {
    display: flex;
    flex-direction: row;
    position: relative;
    fill-opacity: 0;
    transition: fill-opacity 500ms;
    &:hover {
      fill-opacity: 1;
    }
  }
}

.tag {
  margin-bottom: 20px;

    &__box {
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: 30px;
      flex-wrap: wrap;
      border: 1px solid var(--main);
      border-radius: $borderRadius;
      padding: 3px 6px;
      gap: 6px;
      box-sizing: border-box;
      fill:var(--main);
      transition: background-color 1s, fill 1s;

      input {
        width: 100px;
      }
  }

  &__icon {
    cursor:pointer;
  }

  &__tag {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 3px 6px;
    @include font-regular($weight-medium);
    background-color: var(--action-accent);;
    border-radius: 4px;
    color: var(--background);
    fill: var(--background);
  }

  &_active {
    .tag__box {
      border-color: var(--action-accent);
      fill: var(--action-accent);
    }
  }
}


.title-client-phone {
  display: flex;
  flex-direction: row;
}

.temp-page {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}

.setting-sidebar {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 100%;
  border-radius: $borderRadius;
  background-color: var(--background);
}

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  border-radius: $borderRadius;
  background-color: var(--background);

  &__forms {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    gap: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    gap: 15px;
    overflow: auto;
  }

  &__part-left{
    display: flex;
    width: 24%;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
  }

  &__part-right{
    display: flex;
    width: 74%;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
  }

  &__part-table{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
  }

}

.change-avatar {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__border {
    position: relative;
    overflow: hidden;
    width: 250px;
    height: 250px;
    border: 1px solid var(--secondary);
    border-radius: 4px;
  }

  &__mask {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 6px;
    border: 2px solid var(--secondary);
    z-index: 101;
    left: -2px;
    top: -2px;
  }

  &__image {
    position: absolute;
  }
}

.money-movement {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  padding: 10px 10px 10px 20px;

  &__title {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    fill: var(--main);
  }
}

.add-picture {
  width: 250px;
  height: 250px;

  &__show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border-radius: 6px;
    border: 1px solid var(--secondary);
    cursor: pointer;
    text-align: center;
    &:hover {
      border-color: var(--action-accent);
    }

    input[type='file'] {
      outline: 0;
      opacity: 0;
      pointer-events: none;
      user-select: none;
    }
  }

  &__image {
    width: 100%;
  }

  &__add {
    height: 100%;

    input[type='file'] {
      outline: 0;
      opacity: 0;
      pointer-events: none;
      user-select: none;
    }

    .add-picture__label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border: 2px dashed var(--action-accent);
      border-radius: 6px;
      transition: border 300ms ease;
      cursor: pointer;
      text-align: center;

      &:hover {
        border: 2px solid var(--action-accent-lighten);
      }
    }
    .add-picture__label i {
      display: block;
      @include font-large($weight-medium);
    }
  }
}

.add-doc {
  display: flex;
  flex-direction: column;
  gap: 15px;

  input {
    display: none;
  }

  a {
    color: var(--action-accent);
    &:hover {
      color: var(--action-accent-lighten);
    }
  }
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: rgba(36, 36, 36, 0.05);
  margin-top: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  //background: linear-gradient(left, #fff, #e4e4e4);
  background: var(--secondary);
  border: 1px solid var(--secondary);
}
::-webkit-scrollbar-thumb:hover{
  background: var(--secondary);
}
::-webkit-scrollbar-corner {
  display: none;
}

.two-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: flex-end;
}

.split-line {
  height: 0;
  width: 100%;
  border-bottom: 1px solid var(--secondary);
}

.remain {
  padding: 10px;
  border: 1px solid var(--main);
  border-radius: $borderRadius;
  width: 100%;
  box-sizing: border-box;
}

.page-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.alert {
  position: absolute;
  max-width: 1440px;
  width: 100%;
  z-index: 9999999;

  &__message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.25rem;
    margin: 2px 10px;
    border-radius: $borderRadius;
    @include font-regular($weight-bold);

    &_success {
      color: var(--main-background);
      fill: var(--main-background);
      background-color: var(--success);
    }
    &_danger {
      color: var(--main-background);
      fill: var(--main-background);
      background-color: var(--error);
    }
    &_warning {
      color: var(--main-background);
      fill: var(--main-background);
      background-color: var(--orange);
    }
  }
}

.category{
  display: flex;
  flex-direction: column;

  &__head{
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--main);
    color: var(--main);
  }

  &__th{
    @include font-regular($weight-medium);
    text-align: left;

    &_w100{
      width: 100%;
    }

    &_w70{
      width: 70px;
    }
  }

  &__tr{
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    cursor: pointer;
    fill: var(--action-accent);
  }

  &__category-title{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  &__count{
    width: 70px;
  }

  &__subcategory{
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }

}

.number-in-round {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid var(--main);
  border-radius: 15px;
  color: var(--main);
}

.image-input{
  display: none;
}

.select-icon {
  position: absolute;
  max-width: max-content;
  padding: 5px;
  border: 1px solid var(--action-accent);
  border-radius: $borderRadius;
  background-color: var(--background);
  cursor: pointer;
  z-index: 999;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 15px;
    width: 10px;
    height: 10px;
    background-color: var(--background);
    border-top: 1px solid var(--action-accent);
    border-left: 1px solid var(--action-accent);
    transform: rotate(45deg);
  }
}

.loader-container {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.boxVariable {
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 15px;
}

.variableItem {
  color: #4791d1;
  cursor: pointer;
  text-decoration: none;
  font-size: 11px;
}

.variableItem:hover {
  color: #175e9a;
  text-decoration: underline;
}


/*ShortStyle*/
.cur-p {
  cursor: pointer
}

.cur-a {
  cursor: auto
}

.p-r {
  position: relative
}

$gap-sizes: (3, 6, 10, 12, 15);
@each $size in $gap-sizes {
  .g#{$size} {
    gap: #{$size}px;
  }
}

.fm { fill: var(--main) }
.fe { fill: var(--error) }



.jc-c {justify-content: center}
.jc-sb {justify-content: space-between}
.jc-fe {justify-content: flex-end}
.jc-fs {justify-content: flex-start}

.jc-sa {justify-content: space-around}
.ai-c {align-items: center}
.ai-fs {align-items: flex-start}
.ai-fe {align-items: flex-end}
.ai-b {align-items: baseline}

.ta-l {text-align: left}

.mt5 {margin-top: 5px}
.mt10 {margin-top: 10px}
.mt15 {margin-top: 15px}
.mt20 {margin-top: 20px}
.mt25 {margin-top: 25px}
.mt30 {margin-top: 30px}
.mt35 {margin-top: 35px}

$mls: (5, 10, 15, 16, 20, 24);
@each $ml in $mls {
  .ml#{$ml} {
    margin-left: #{$ml}px;
  }
}

.z9999 {
  z-index: 9999;
}

.mb5 {margin-bottom: 5px}

.pd5 {padding: 5px}
.pd10 {padding: 10px}
.pd15 {padding: 15px}
.pd20 {padding: 20px}
.pd25 {padding: 25px}
.pd30 {padding: 30px}
.pd35 {padding: 35px}

.pt10 {padding-top: 10px}

@for $w from 1 to 50 {
  .w#{$w * 10} {
    width: #{$w * 10}px;
  }
}

$width: (15, 600, 700, 800);
@each $w in $width {
  .w#{$w} {
    width: #{$w}px;
  }
}

$heights: (30, 40, 50, 92, 300);
@each $h in $heights {
  .h#{$h} {
    height: #{$h}px;
  }
}

.ovf-a{ overflow: auto }

.w100p { width: 100% }
.w25p { width: 25% }
.w75p { width: 75% }
.h100p { height: 100% }

.bcsb {background-color: var(--second-bacground)}

.cm { color: var(--main) }
.cs { color: var(--secondary) }



