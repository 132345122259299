

.header-order-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  h4 {
    color: var(--main);
    @extend .nowrap
  }


  &__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .status-container {
      width: max-content;
    }
  }
}

.form-order-editor {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 15px 0;
  gap: 15px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;

  h5 {
    @extend .nowrap
  }

  &__set-forms {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
  }

  &__block-forms {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__payment-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

.client-card {
  display: flex;
  flex-direction: column;

  &__label {

  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 6px;
    gap: 4px;
    border-left: 6px solid var(--action-accent);
    border-radius: $borderRadius;
    color: var(--secondary);
    fill: var(--secondary);
    @extend %shadow-3;
  }

  &__name-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__name {
    color: var(--action-accent);
    display: flex;
    flex-direction: row;
    gap: 6px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: var(--action-accent-lighten);
      fill: var(--action-accent-lighten);
    }
  }


  &__icon-cancel {
    cursor: pointer;
    fill: var(--action-accent);

    &:hover {
      fill: var(--action-accent-lighten);
    }
  }
}

.history-order-editor {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  box-sizing: border-box;
  width: 350px;
  background-color: var(--second-bacground);
  height: 100%;
  border-left: 1px solid var(--secondary);
  border-radius: 0 $borderRadius $borderRadius 0;
  flex-shrink: 0;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__events {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    //max-height: 685px;
    height: 100%;
    overflow: auto;
    color: var(--main-darken);
    fill: var(--second-bacground);

    div.history-order-editor__item:first-child {
      padding: 0 0 5px 0;
    }
  }
  &__icon {
    padding: 2px;
    border-radius: 2px;
  }

  &__event {
    border-left: 2px solid;
    padding: 4px 0;
  }
  &__event-status {
    border-left: 2px solid;
    padding-bottom: 4px;
  }

  &__event-date {
    text-align: center;
    padding: 4px 0;
  }

  &__item {
    position: relative;
    left: -10px;
    padding: 5px 0;
  }

  &__item-status {
    @include font-regular($weight-medium);
    color: var(--second-bacground);
    padding: 4px 10px;
    border-radius: $borderRadius;
  }

  &__message {
    display: flex;
    margin-left: 30px;
    max-width: 240px;
    gap: 5px;
    fill: var(--secondary);
    color: var(--secondary);
    align-items: center;

    &_colm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__comment {
    padding-top: 15px;
    color: var(--secondary);

    .textarea {
      width: 100%;
      overflow: hidden;
      color: var(--main);
    }
  }

  ::-webkit-scrollbar-thumb {
    background: var(--background);
  }
  ::-webkit-scrollbar-thumb:hover{
    background: var(--background);
  }
}

.set-work-materials {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  color: var(--secondary);

  .select {
    width: 100%;
  }

  &__text {
    margin-bottom: 10px;
  }
}

//@media screen and (max-height: 840px) {
//  .form-order-editor {
//    overflow: auto;
//  }
//
//  //.history-order-editor__events {
//  //  max-height: 100%;
//  //}
//}



.order-print {
  position: relative;

  &__drop-list {
    padding: 15px;
    gap: 15px !important;
    top: 35px !important;
    left: -30px !important;
  right: auto !important;
  }
}

