

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: rgba(44, 44, 44, 0.6);

  &_z20 {
    z-index: 20;
  }
  &_right {
    display: flex;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: $boxPadding;
    justify-content: right;
  }

  &__box {
    position: relative;
    display: flex;
    border: 1px solid var(--main);
    gap:15px;
    border-radius: $borderRadius;
    background-color: var(--background);
    max-height: 100%;
    box-sizing: border-box;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.42);

    &_editor {
      flex-direction: column;
      padding: 20px;
    }
  }

  &__box-right {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--main);
    gap:15px;
    border-radius: $borderRadius;
    background-color: var(--background);
    height: 100%;
    width: 600px;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.42);

    &_w300 {width: 300px;}
    &_w800 {width: 800px;}

    &_history {
      flex-direction: row;
      width: 840px;
      padding: 0;
      gap: 0;
    }
  }

  &__body-right {
    display:flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding-right: 10px;
    gap: 15px;
    box-sizing: border-box;

    &_history {
      width: 100%;
      padding: 20px;
    }
  }

  &__body {
    display:flex;
  }

  &__body_filter {
    flex-direction: column;
    gap: 15px;
    padding: 15px 0;
  }

  &__body-order {
    flex-direction: row;
    max-height: 860px;
    min-height: 860px;
    box-sizing: border-box;

  }
  &__body-client {
    flex-direction: column;
    max-height: 600px;
    min-height: 600px;
    width: 520px;
    box-sizing: border-box;
    overflow: auto;
    gap: 15px;
    padding-right: 10px;
  }

  &__body-order-form {
    display:flex;
    flex-direction: column;
    padding: 20px 10px 20px 20px;
    gap: 7px;
    max-height: 100%;
    width: 540px;
    box-sizing: border-box;
  }

  &__body-cell {
    padding: 15px;
    flex-direction: column;
    color: var(--main);
  }

  &__body-editor {
    flex-direction: column;
    gap: 15px;
    max-height: 100%;
    box-sizing: border-box;
    overflow: auto;

    &_small{
      overflow: visible;
    }
  }

  &__body-payment {
    flex-direction: column;
    gap: 15px;
    max-height: 100%;
    box-sizing: border-box;
    padding-right: 10px;
    overflow: auto;
  }

  &__forms {
    display: grid;
    grid-template-columns: 220px 220px;
    grid-auto-rows: 55px;
    gap: 15px;
  }

  &__block-forms {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;
    }
  }
}

@media screen and (max-height: 850px) {
  .modal__body-order {
    max-height: 100%;
    min-height: 100%;
  }
}