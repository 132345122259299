/* Sidebar*/


.sidebar {
  padding: $boxPadding $boxPadding/2 $boxPadding $boxPadding;

  &__body {
    //padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 140px;
    box-sizing: border-box;
    background: var(--background);
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.42);
    border-radius: $borderRadius;
  }

  &__avatar {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 100px;
    height: 100px;
  }

  &__img-avatar {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
  }

  &__hr {
    width: 75%;
    height: 2px;
    border: none;
    border-top: 1px solid var(--secondary);
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 18px;
    gap: 6px;
    box-sizing: border-box;
    width: 140px;
    color: var(--secondary);
    text-decoration: none;
    fill: var(--secondary);

    &_active {
      padding: 12px 14px;
      color: var(--accent);
      background: var(--secondary);
      border-left: 4px solid var(--success);
      fill: var(--accent);
    }

    &_active-settings {
      color: var(--accent);
      background: var(--secondary);
    }

    &:hover {
      color: var(--accent);
      fill: var(--accent);
    }
  }

  &__icon {
    display: block;
    width: 16px;
    height: 16px;
  }

  &__title {
    @include font-large;
  }

}

@media screen and (max-width: 820px) {
  .sidebar {

    &__body {
      width: 80px;
    }

    &__avatar {
      padding: 5px;
      width: 50px;
      height: 50px;
    }

    &__item {
      width: 80px;
      padding: 10px 28px;

      &_active {
        padding: 10px 24px;
        border-width: 4px;
      }
    }

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__title {
      display: none;
    }
  }
}

