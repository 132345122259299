.login{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__body {
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 15px;
    padding: 5px;
  }

  &__error-message {
    color: var(--error);
    @include font-regular($weight-medium);
  }

  &__button {
    text-align: center;
    horiz-align: center;
    height: 50px;
    width: 100%;
    border-radius: $borderRadius;
    @include font-large($weight-medium);
    color: var(--background);
    background-color: var(--success);

    &:hover {
      background-color: var(--success-lighten);
    }
  }
}