
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    color: var(--secondary);
    fill: var(--secondary);
  }

  &_slide-one {

    .checkbox__box {
      width: 30px;
      height: 8px;
      background: #333;
      position: relative;
      border-radius: 40px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
      0 1px 0 rgba(255, 255, 255, 0.2);



      label {
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        top: -2px;
        left: -3px;
        cursor: pointer;
        background: var(--secondary);
        border-radius: 40px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
        transition: all 0.4s ease;
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label {
          left: 20px;
          background: var(--main);
        }

      }
    }
  }

  &_slide-two {

    .checkbox__box {
      width: 36px; // ширина блока
      height: 16px; // высота блока
      background: #333; // цвет фона кнопки
      position: relative;
      border-radius: 20px;
      box-shadow: inset 0 1px 1px rgba(25, 25, 25, 0.5),
      0 1px 0 rgba(255, 255, 255, 0.1);

      &:after {
        content: "";
        position: absolute;
        top: 8px;
        left: 7px;
        height: 1px;
        width: 22px;
        background: var(--main-background); // цвет линии внутри
        border-radius: 20px;
        box-shadow: inset 0 1px 1px rgba(25, 25, 25, 0.5),
        0 1px 0 rgba(255, 255, 255, 0.2);
      }

      label {
        display: block;
        width: 13px;
        height: 13px;
        cursor: pointer;
        position: absolute;
        top: 2px;
        z-index: 1;
        left: 3px;
        background: var(--secondary); // основной круг
        border-radius: 25px;
        transition: all 0.4s ease;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
        background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);

        &:after {
          content: "";
          width: 7px;
          height: 7px;
          position: absolute;
          top: 3px;
          left: 3px;
          background: var(--disabled); // внутренний шар
          border-radius: 50px;
          box-shadow: inset 0 1px 1px rgba(25, 25, 25, 0.7),
          0 1px 0 rgba(118, 118, 118, 0.9);
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label {
          left: 20px; // положение шара после
          &:after {
            background: var(--success);
          }
        }
      }
    }
  }

  &_slide-three {

    .checkbox__box {
      width: 52px;
      height: 20px;
      background: #333;
      position: relative;
      border-radius: 30px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
      0 1px 0 rgba(255, 255, 255, 0.2);

      &:after {
        content: "OFF";
        color: var(--main-background);
        position: absolute;
        right: 4px;
        z-index: 0;
        font: 10px/22px Inter, sans-serif;
        font-weight: bold;
        //text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
      }

      &:before {
        content: "ON";
        color: var(--success);
        position: absolute;
        left: 5px;
        z-index: 0;
        font: 10px/22px Inter, sans-serif;
        font-weight: bold;
      }

      label {
        display: block;
        width: 24px;
        height: 15px;
        cursor: pointer;
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 1;
        background: var(--secondary);
        background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 50px;
        transition: all 0.4s ease;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label {
          left: 25px;
        }
      }
    }
  }

  &_rounded-one {

    .checkbox__box {
      width: 20px;
      height: 20px;
      position: relative;
      background: var(--secondary);
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      border-radius: 50px;
      box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);

      label {
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        left: 2px;
        top: 2px;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        border-radius: 50px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
        0 1px 0 rgba(118, 118, 118, 1);

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: 2px;
          left: 2px;
          background: var(--success);
          background: linear-gradient(top, var(--success) 0%, var(--background) 100%);
          opacity: 0;
          border-radius: 50px;
          box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);
        }

        &:hover::after {
          opacity: 0.3;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }
      }
    }
  }

  &_rounded-two {
    .checkbox__box {
      width: 20px;
      height: 20px;
      position: relative;
      background: var(--secondary);
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      border-radius: 50px;
      box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);

      label {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2px;
        left: 2px;
        cursor: pointer;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        border-radius: 50px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
        0 1px 0 rgba(118, 118, 118, 1);

        &:after {
          content: "";
          width: 8px;
          height: 4px;
          position: absolute;
          top: 3px;
          left: 3px;
          border: 3px solid var(--main);
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }

        &:hover::after {
          opacity: 0.3;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }
      }
    }
  }

  &_squared-one {

    .checkbox__box {
      width: 20px;
      height: 20px;
      position: relative;
      background: var(--secondary);
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);

      label {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2px;
        left: 2px;
        cursor: pointer;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
        0 1px 0 rgba(118, 118, 118, 1);

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: 2px;
          left: 2px;
          background: var(--success);
          background: linear-gradient(top, var(--success) 0%, var(--background) 100%);
          box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);
          opacity: 0;
        }

        &:hover::after {
          opacity: 0.3;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }
      }
    }
  }

  &_squared-two {

    .checkbox__box {
      width: 20px;
      height: 20px;
      position: relative;
      background: var(--secondary);
      background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
      box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);

      label {
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        left: 2px;
        top: 2px;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
        0 1px 0 rgba(118, 118, 118, 1);

        &:after {
          content: "";
          width: 8px;
          height: 4px;
          position: absolute;
          top: 3px;
          left: 3px;
          border: 3px solid var(--main);
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }

        &:hover::after {
          opacity: 0.3;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }
      }
    }
  }

  &_squared-three {
    .checkbox__box {
      width: 16px;
      position: relative;
      display: flex;
      align-items: center;

      label {
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
        0 1px 0 rgba(255, 255, 255, 0.1);

        &:after {
          content: "";
          width: 8px;
          height: 4px;
          position: absolute;
          top: 3px;
          left: 3px;
          border: 2px solid var(--main);
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }

        &:hover::after {
          opacity: 0.3;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }
      }
    }
  }

  &_squared-four {
    .checkbox__box {
      width: 16px;
      position: relative;
      display: flex;
      align-items: center;

      label {
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--secondary);
        background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 4px;
        box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);

        &:after {
          content: "";
          width: 8px;
          height: 4px;
          position: absolute;
          top: 3px;
          left: 2px;
          border: 3px solid var(--background);
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }

        &:before {
          content: "";
          width: 8px;
          position: absolute;
          top: 6px;
          left: 3px;
          border-top: 3px solid var(--background);
          background: transparent;
          opacity: 0;
        }

        &:hover::after {
          opacity: 0.5;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }

        &:indeterminate + label:before {
          opacity: 1;
        }

        &:checked {
          .checkbox__label {
            color: var(--main);
          }
        }

      }
    }
  }

  &_squared-five {
    .checkbox__box {
      width: 16px;
      position: relative;
      display: flex;
      align-items: center;
      color: var(--action-accent);

      .checkbox__label {
        color: var(--action-accent);
      }

      label {
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--secondary);
        background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 4px;
        box-shadow: inset 0 1px 1px var(--secondary), 0 1px 3px rgba(0, 0, 0, 0.5);

        &:after {
          content: "";
          width: 8px;
          height: 4px;
          position: absolute;
          top: 3px;
          left: 2px;
          border: 3px solid var(--main);
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }

        &:before {
          content: "";
          width: 8px;
          position: absolute;
          top: 6px;
          left: 3px;
          border-top: 3px solid var(--main);
          background: transparent;
          opacity: 0;
        }

        &:hover::after {
          opacity: 0.5;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked + label:after {
          opacity: 1;
        }

        &:indeterminate + label:before {
          opacity: 1;
        }

        &:checked .checkbox__label {
          color: var(--main);
        }
      }
    }
  }

  &_checked {
    .checkbox__label {
      color: var(--main);
      fill: var(--main);
    }
  }

  &_disabled{
    .checkbox__label {
      color: var(--secondary);
      fill: var(--secondary);
    }
  }
}
