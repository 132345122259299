.cashbox {
  display: flex;
  flex-direction: column;
  padding: 10px 22px;
  width: 200px;
  height: 70px;
  box-sizing: border-box;
  gap:10px;
  position: relative;
  align-items: center;
  border: 1px solid var(--secondary);
  border-radius: $borderRadius;
  fill-opacity: 0;
  color: var(--secondary);
  fill: var(--secondary);
  transition: color 100ms, border-color 100ms, fill-opacity 100ms, height 50ms;

  &__balance {
    @include font-large($weight-medium);
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }


  &__icons {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px;
  }

  &__buttons {
    display: none;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }

  &_active {
    border-color: var(--action-accent);
    color: var(--main);
    fill: var(--action-accent);
    height: 145px;
    fill-opacity: 1;

    .cashbox__buttons {
      display: flex;
    }
  }
}